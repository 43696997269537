import { EVENT_TYPE } from "../../../storecontextlayer/enums/event-type";
import { fetchStoresAroundRendering } from "../store-context-search/store-context-search";
import { customerContextCity, customerContextPostcode, ID_SELECTOR, STORE_CARD, STORE_LAYER_TITLE, STORE_SEARCH } from "../../customercontextlayer.src";
import { hitEventTms } from "integration-web-core--socle/js/assets/commons/_tms.js";
import { applyTrackingAndContextCookieOnStoreSearchBlock, integrateHtmlToStoreSearchBlock } from "../store-search-result/store-search-result";
import { retrieveCustomerContextCookieValue } from "../../../common/cookie-finder";
export function initStoreContextCard(storeContextLayerBlock) {
  const STORE_CHANGE_BUTTON = 'customer-context-change-button';
  const KL_HIDDEN_CLASS = 'kl-hidden';
  const layerTitleText = 'Wybierz swój sklep';
  const storeSearchContainer = storeContextLayerBlock.querySelector(`[${ID_SELECTOR}="${STORE_SEARCH}"]`);
  const storeCardContainer = storeContextLayerBlock.querySelector(`[${ID_SELECTOR}="${STORE_CARD}"]`);
  const storeChangeButton = storeContextLayerBlock.querySelector(`[${ID_SELECTOR}="${STORE_CHANGE_BUTTON}"]`);
  const storeContextLayerTitle = storeContextLayerBlock.querySelector(`${STORE_LAYER_TITLE}`);
  storeChangeButton.addEventListener(EVENT_TYPE.CLICK, () => {
    initStoreChangeButtonTracking();
    const customerContextCookie = retrieveCustomerContextCookieValue();
    // TODO remove check of latitude and longitude when cookie will be removed if values not present
    if (customerContextCookie && customerContextCookie.latitude && customerContextCookie.longitude) {
      const storeSearchBlock = storeContextLayerBlock.querySelector(`[${ID_SELECTOR}="${STORE_SEARCH}"]`);
      fetchStoresAroundRendering(customerContextCookie.latitude, customerContextCookie.longitude).then(result => {
        integrateHtmlToStoreSearchBlock(result, storeSearchBlock);
        customerContextPostcode = customerContextCookie.postcode;
        customerContextCity = customerContextCookie.city;
        applyTrackingAndContextCookieOnStoreSearchBlock(storeSearchBlock, customerContextCookie.latitude, customerContextCookie.longitude);
      });
    }
    storeCardContainer && storeCardContainer.classList.add(KL_HIDDEN_CLASS);
    storeSearchContainer && storeSearchContainer.classList.remove(KL_HIDDEN_CLASS);
    storeContextLayerTitle.innerHTML = layerTitleText;
    sendCdlEventOnSearchStepDisplay();
  });
}
function sendCDLEvent(eventContent) {
  hitEventTms(window, 'cdl_event', JSON.stringify(eventContent));
}
function initStoreChangeButtonTracking() {
  sendCDLEvent({
    'event_name': 'button.click',
    'event_data': {
      'feature_name': 'header',
      'step_name': 'information content',
      'funnel_name': 'contextualization layer',
      'button_name': 'Change store'
    }
  });
}
function sendCdlEventOnSearchStepDisplay() {
  sendCDLEvent({
    'event_name': 'step.display',
    'event_data': {
      'feature_name': 'header',
      'step_name': 'store search',
      'funnel_name': 'contextualization layer'
    }
  });
}